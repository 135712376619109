import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import { ContentWrapper } from "../../assets/styles/base";
import MetaComponents from "../../components/seo/MetaComponents";
import GreyHeader from "../../components/GreyHeader";
import TipsCard from "../../components/tipsAndTricks/TipsCard";
import { breakpoints } from "../../constants/breakpoints";
import { TIPS_AND_TRICKS_LIST } from "../../constants/tipsAndTricksList";

const Index = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <GreyHeader title="Wissenswertes zu deiner Immobilienfinanzierung" />
            <ContentWrapper>
                <TipsList>
                    {Object.keys(TIPS_AND_TRICKS_LIST).map((key, index) => {
                        return (
                            <li key={index}>
                                <TipsCard data={TIPS_AND_TRICKS_LIST[key]} />
                            </li>
                        );
                    })}
                </TipsList>
            </ContentWrapper>
        </Layout>
    );
};

const TipsList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, 260px);
    justify-content: center;
    grid-gap: 36px;

    list-style: none;
    margin: 50px auto 0;
    padding: 0;
    flex-wrap: wrap;

    @media (min-width: ${breakpoints.mobileBigMax}) {
        justify-content: start;
    }
`;

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["header", "footer", "page.index"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
